import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import BackButton from "../components/back-button";

import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Grid, Cell } from "baseui/layout-grid";
import { Heading, HeadingLevel } from "baseui/heading";
import { Label2 } from "baseui/typography";

const newsTemplate = ({
  data: {
    newsArchive: { title, content, date },
  },
}) => {
  const [css] = useStyletron();
  const styles = css({
    fontSize: "18px",
    fontWeight: "normal",
    lineHeight: "28px",
    color: "#545454",
  });
  return (
    <Layout>
      <SEO title={title} description={title} />
      <Grid>
        <Cell span={[4, 6, 8]}>
          <HeadingLevel>
            <Heading>{title}</Heading>
            <Label2>{date}</Label2>
            <Block className={styles}>
              {/* .innerhtml and themes styles described with global styles in layout.css */}
              <Block
                className={`innerhtml cmf`}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </Block>
            <BackButton title="Назад" />
          </HeadingLevel>
        </Cell>
      </Grid>
    </Layout>
  );
};
export default newsTemplate;

export const query = graphql`
  query($slug: String) {
    newsArchive(slug: { eq: $slug }) {
      title
      content
      date(formatString: "DD MMMM YYYY", locale: "ru")
    }
  }
`;
